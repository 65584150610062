import request from '@/utils/request';
//危险品实验申请前置审批  新增 修改
export function Api_YyMAADangerousApplication_AddEditInfo(params){
    return request('/YyMAADangerousApplication/AddEditInfo',{
        method:'post',
        data: params
    })
}
//危险品实验申请前置审批  详情
export function Api_YyMAADangerousApplication_GetInfo(params){
    return request('/YyMAADangerousApplication/GetInfo',{
        method:'post',
        data: params
    })
}
//危险品实验申请前置审批  列表
export function Api_YyMAADangerousApplication_GetPage(params){
    return request('/YyMAADangerousApplication/GetPage',{
        method:'post',
        data: params
    })
}