<template>
    <div class="FormBox">
        <van-form  @submit="onSubmit" :show-error='false' ref="form" :scroll-to-error='true'>
            <div class="EventBox" >
                <!-- <h4 class="FormBoxTitle">仪器设备预约</h4> -->
                <van-cell-group>
                    <van-field :disabled="FromData.DA_AuditStatus == 1" required v-model="FromData.DA_ProjectName"  label="实验项目名称" placeholder="实验项目名称"  :rules="[{ required: true, message: '实验项目名称' }]" />
                    <van-field :disabled="FromData.DA_AuditStatus == 1" required v-model="FromData.DA_UserName"  label="使用人姓名" placeholder="使用人姓名"  :rules="[{ required: true, message: '使用人姓名' }]" />
                    <van-field :disabled="FromData.DA_AuditStatus == 1" required v-model="FromData.DA_UserPhone" maxlength="11" label="使用人手机" placeholder="使用人手机"  :rules="[{ required: true, message: '使用人手机' },{ validator, message: '请输入正确手机号' }]" />
                    <van-field :disabled="FromData.DA_AuditStatus == 1" required v-model="FromData.DA_Type"  label="危险品种类" placeholder="危险品种类(列：有毒、强酸...)"  :rules="[{ required: true, message: '危险品种类' }]" />
                </van-cell-group>
                <div class="BoxRow">
                    <h4>危险品名称、数量</h4>
                    <van-field v-model="FromData.DA_Info"  placeholder="危险品名称、数量" 
                    :disabled="FromData.DA_AuditStatus == 1"
                    type="textarea"
                    autosize
                    rows="4"
                    :rules="[{ required: true, message: '危险品名称、数量' }]"
                    />
                </div>
                <div class="BoxRow" v-if="FromData.DA_AuditStatus == 1">
                    <h4 class="ImgBoxH4 van-hairline--bottom">审批单照片</h4>
                    <div class="Imgbox">
                        <img :src="FromData.DA_AuditPhotos" alt="">
                    </div>
                </div>
            </div>
          
        </van-form>
        <div class="InfButtom van-hairline--top">
            <van-button v-if="FromData.DA_AuditStatus == 0" :type="isEdit?'primary':'danger'" block @click="SubmitForm">{{isEdit?'修改危险品申请':'新增危险品申请'}}</van-button>
            <van-button v-else type="primary" block @click="$router.push({path:'/makeAppointment/laboratoryInfo',query:{DA_ID:FromData.DA_ID,query:{fromPath:encodeURI($route.path)}}})">马上预约</van-button>
        </div>
    </div>
</template>
<script>
import { Form,CellGroup,Field,Button,Icon,Toast } from 'vant';
import { Api_YyMAADangerousApplication_AddEditInfo , Api_YyMAADangerousApplication_GetInfo } from "@/api/danger";
const ObjectData = {
    DA_ProjectName:'',
    DA_UserName:'',
    DA_UserPhone:'',
    DA_Type:'',
    DA_Info:'',
    DA_AuditStatus:0
}
export default {
    components:{
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        [Icon.name]: Icon,
        [Toast.name]: Toast,
    },
    props:['isEdit'],
    data() {
        return {
           FromData:Object.assign({},ObjectData),
           
        }
    },
    created(){
        if(this.$route.params.id){
            Api_YyMAADangerousApplication_GetInfo({
                DA_ID:this.$route.params.id
            }).then(res=>{
  
                this.FromData = res
            })
        }
    },
    methods:{
        //手机号验证
        validator(val) {
            return /1\d{10}/.test(val);
        },
        SubmitForm(){
            this.$refs.form.submit()
        },
        
        onSubmit(val){
            Api_YyMAADangerousApplication_AddEditInfo(this.FromData).then(res=>{
                if(this.isEdit){
                    this.$toast('修改成功')
                }else {
                     this.$toast('新增成功')
                    this.FromData = Object.assign({},ObjectData);
                    this.$refs.form.resetValidation()
                }
               
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.FormBox {
    flex: 1;
    display: flex;
    height: calc(100% - 46px);
    flex-direction: column;
    .van-form {
        flex: 1;
        padding-bottom: 10px;
        overflow-y: auto;
    }
    .EventBox {
        padding-top: 10px;
        background: #f2f2f2;
        .FormBoxTitle {
            font-size: 14px;
            background: #fff;
            padding: 8px 14px;
            color: #333;
        } 
    }
}
.BoxRow {
    background: #fff;
    margin-top: 8px;
    h4 {
        font-size: 14px;
        color: #333;
        padding: 0px 14px;
        padding-top: 10px;
    }
    /deep/ .van-field {
        padding: 10px 10px;
    }
    /deep/ .van-field__body {
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        padding:5px;
    }
    p {
        font-size: 13px;
        color: #ee0a24;
        padding: 0 14px;
        line-height: 22px;
    }
    .Tips {
        padding-bottom: 10px;
    }
    
     .Imgbox {
        text-align: center;
        padding: 10px 10px;
        img {
            width: 60%;
            border-radius: 8px;
        }
      
    }
    .ImgBoxH4 {
        padding: 0;
        padding-left: 10px;
        line-height: 39px;
    }
}


.InfButtom {
    padding: 10px 14px;
    background-color: #fff;

    .van-button {
        line-height: 38px;
        height: 38px;
    }
}
</style>